/* .swiper-today-slider-pagination .swiper-pagination-bullet-active {
  background-color: theme(colors.primary.10);
} */

.swiper-button-prev,
.swiper-button-next {
  /* color: theme(colors.black); */
  top: 50%;
  transform: translateY(-50%);
}

.swiper-button-next {
  left: 0;
  right: auto;
}

.swiper-button-next {
  left: auto;
  right: 0;
}

.custom-swiper-scrollbar {
  --swiper-scrollbar-drag-bg-color: #1a1a1a;
  height: 12px;
  background-color: rgb(var(--sys-primary-primary-container));
  border-radius: 40px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.swiper-today-slider-pagination .swiper-pagination-bullet {
  height: 14px !important;
  width: 14px !important;
  margin: 0 10px !important;
}

.swiper-today-slider-pagination .swiper-pagination-bullet-active {
  height: 14px !important;
  width: 14px !important;
}

.swiper-today-slider-pagination .swiper-pagination-bullet-active {
  background-color: rgb(var(--sys-secondary-secondary));
}

.swiper-today-slider-pagination
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 10px !important;
}

.swiper-scrollbar-drag:hover {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .swiper-today-slider-pagination .swiper-pagination-bullet {
    height: 8px !important;
    width: 8px !important;
    margin: 0 6px !important;
  }

  .swiper-pagination-bullet-active {
    height: 8px !important;
    width: 8px !important;
  }
}

.articlesHighlights-swiper-scrollbar {
  --swiper-scrollbar-drag-bg-color: #1a1a1a;
}
